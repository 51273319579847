.App {
  text-align: center;
}

.App-logo {
  height: 80px;
  margin-bottom: 20px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.main {
  padding: 20px;
}

.form-container {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.form-section {
  margin-bottom: 20px;
}

.form-section h2 {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.checkbox-group {
  flex-direction: row;
  align-items: center;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input[type="checkbox"] {
  margin-left: 10px;
}

.energy-consumption-group,
.energy-source-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.energy-consumption-group input,
.energy-source-group select,
.energy-source-group input {
  margin-right: 10px;
}

.energy-consumption-group button,
.energy-source-group button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.energy-consumption-group button:hover,
.energy-source-group button:hover {
  background-color: #d32f2f;
}

.submit-button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #21a1f1;
}

.response-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  text-align: left;
}

.charts {
  text-align: left;
  margin-top: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.form-container,
.response-container {
  flex: 1;
  max-width: 48%;
}

.response-container pre {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
  max-height: 400px; /* oder ein anderer Wert, um die maximale Höhe zu begrenzen */
}

